import { Box, Typography } from "@mui/joy";
import React from "react";
import { Link } from "react-router-dom";

const DocTask = ({ task }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			{task?.task_progress?.value && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontSize: "14px", fontWeight: "700" }}
					>
						Documents
					</Typography>
					{task?.task_progress?.value?.task_question?.doc_urls.map((doc, index) => (
						<Link
							to={doc}
							target='_blank'
							style={{
								textDecoration: "none",
								color: "#3838f1",
								width: "100%",
								fontSize: "14px",
								fontWeight: "600",
								borderRadius: "5px",

								px: 2,
								fontSize: "1rem",
							}}
						>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									bgcolor: "#F5F5F5",
									p: "10px 16px",
									borderRadius: "5px",
								}}
							>
								Reading Doc {index + 1}
							</Box>
						</Link>
					))}
				</Box>
			)}
		</Box>
	);
};

export default DocTask;
