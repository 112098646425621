import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { clearAll, setUnsavedChanges, setUpdatedBatchInfo, updateBatch } from "../../../Store/slices/batchSlice";
import { fetchBatchByIdThunk, fetchStudentsByBatchIdThunk, publishBatchThunk, updateBatchByIdThunk } from "../../../Store/actions/batchAction";
import BatchDetails from "./batch-info-components/batch-details";
import { batchType } from "../../../Constants/batch-tabs";
import { Box, Button, Tab, TabList, Tabs, Typography } from "@mui/joy";
import ScheduleCourse from "./batch-info-components/schedule-course";
import VerifyStudents from "../add-new-batch/batch-components/verify-student";
import BatchEvents from "./batch-info-components/batch-event";
import WithAuth from "../../utils/WithAuth";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import axios from "axios";
import { API_URL } from "../../../Config";
import TeacherDetails from "../add-new-batch/batch-components/teacher-details/teacher-details";
import { UserRole } from "../../../Constants/user-role";
import { fetchCourseDetailsThunk } from "../../../Store/actions/courseAction";
import { fetchTopicSessionByBatchIdThunk } from "../../../Store/actions/topicSessionAction";
import InfoIcon from "@mui/icons-material/Info";
import { checkAllNodesInTopicSessions, getAllTopics } from "../../../helpers/node";
import Loader from "../../utils/Loader";

const BatchInfo = () => {
	const location = useLocation();
	const batchId = location.pathname.split("/")[2];
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { batchDetails, students, error, loading, unsavedChanges, updatedBatchInfo } = useSelector((state) => state.batch);
	const { courseData, loading: courseDataLoader } = useSelector((state) => state.course);
	const { topicSessions } = useSelector((state) => state.topic);
	const { user } = useSelector((state) => state.user);

	const [activeTab, setActiveTab] = useState(batchType.batchDetails);
	const [publishLoader, setPublishLoader] = useState(false);

	useEffect(() => {
		if (batchDetails === null && batchId) dispatch(fetchBatchByIdThunk(batchId));
		// dispatch(fetchStudentsByBatchIdThunk(batchId));
	}, []);
	useEffect(() => {
		if (batchDetails?.data?.courses.length > 0) {
			dispatch(fetchCourseDetailsThunk({ courseId: batchDetails?.data?.courses[0], batchId: batchDetails?.data?.batch_id }));
			dispatch(fetchTopicSessionByBatchIdThunk(batchDetails?.data?.batch_id));
			dispatch(fetchStudentsByBatchIdThunk({ batchId: batchDetails?.data?.batch_id }));
		}
	}, [batchDetails?.data?.batch_id]);

	const handleTabChange = (event, newValue) => {
		setActiveTab(newValue);
	};

	const handlePublishBatch = async () => {
		if (!updatedBatchInfo && !unsavedChanges) {
			dispatch(showToast({ toastMessage: "No changes to publish", toastType: ToastType.ERROR }));
			return;
		}

		if (updatedBatchInfo && !unsavedChanges) {
			const response = await dispatch(updateBatchByIdThunk(updatedBatchInfo));
			if (response?.payload?.data) {
				dispatch(updateBatch(response.payload.data));
				dispatch(setUpdatedBatchInfo(null));
				dispatch(showToast({ toastMessage: "Batch Updated Successfully", toastType: ToastType.SUCCESS }));
				return;
			}
		}
		if (topicSessions.length === 0) {
			dispatch(showToast({ toastMessage: "Please schedule alteast one topic for publishing the batch", toastType: ToastType.ERROR }));
			return;
		}

		if (students?.data?.length === 0) {
			dispatch(showToast({ toastMessage: "Please add students to the batch before publishing", toastType: ToastType.ERROR }));
			return;
		}

		const batchInfo = {
			batch_id: batchDetails?.data?.batch_id,
			publish_status: "PENDING",
			...updatedBatchInfo,
		};

		const batchPublishable = await dispatch(updateBatchByIdThunk(batchInfo));
		if (batchPublishable?.payload?.data) {
			setUpdatedBatchInfo(null);
			setPublishLoader(true);
			const response = await dispatch(publishBatchThunk(batchId));
			if (response?.payload?.data) {
				dispatch(setUnsavedChanges(null));
				setPublishLoader(false);
				dispatch(showToast({ toastMessage: "Batch Published Successfully", toastType: ToastType.SUCCESS }));
				navigate("/batches");
			}
		} else {
			setPublishLoader(false);
			dispatch(
				showToast({
					toastMessage: "Publish failed for some topics, please click on Publish again",
					toastType: ToastType.ERROR,
				})
			);
		}
	};

	if (error) {
		dispatch(showToast({ toastMessage: error, toastType: ToastType.ERROR }));
		setTimeout(() => {
			navigate("/batches");
		}, 3000);
		return <div>{error}</div>;
	}

	const renderTabsInfo = (activeTab) => {
		switch (activeTab) {
			case batchType.batchDetails:
				return <BatchDetails />;
			case batchType.verifyStudents:
				return <VerifyStudents batchId={batchId} />;
			case batchType.teacherDetails:
				return <TeacherDetails batchId={batchId} />;
			case batchType.scheduleCourse:
				return <ScheduleCourse />;
			case batchType.batchEvents:
				return <BatchEvents />;
			default:
				return <BatchDetails />;
		}
	};

	return (
		<Box sx={{ width: "100%", maxWidth: 1000, margin: "auto", p: 2 }}>
			<Box sx={{}}>
				{user?.role !== UserRole.TEACHER && (
					<Box sx={{ display: "flex", justifyContent: "space-between", mb: 3, mt: -2, alignItems: "center" }}>
						<Typography level='h6'>{batchDetails?.data?.name ? `Batch: ${batchDetails?.data?.name}` : "Batch Details"}</Typography>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							{(unsavedChanges === batchDetails?.data?.batch_id || (updatedBatchInfo !== null && updatedBatchInfo?.batch_id === batchDetails?.data?.batch_id)) && (
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										gap: 1,
										border: "1px solid orange",
										padding: ".45rem",
										borderRadius: "sm",
									}}
								>
									<InfoIcon
										color='warning'
										sx={{
											fontSize: "1rem",
										}}
									/>
									<Typography
										sx={{
											color: "orange",
											fontSize: ".8rem",
											fontWeight: "bold",
										}}
									>
										There are un-published changes
									</Typography>
								</Box>
							)}
							<Button
								sx={{ alignSelf: "center", backgroundColor: "#000" }}
								onClick={handlePublishBatch}
								// disabled={disablePublishButton()}
								loading={publishLoader}
							>
								Publish Batch
							</Button>
						</Box>
					</Box>
				)}
				<Tabs
					value={activeTab}
					onChange={handleTabChange}
					sx={{
						mt: -1,
					}}
				>
					<TabList sx={{ backgroundColor: "#f4f4f4" }}>
						<Tab value={batchType.batchDetails}> 01. Batch Details</Tab>
						<Tab value={batchType.verifyStudents}>02. Student Details</Tab>
						<Tab value={batchType.teacherDetails}>03. Teacher Details</Tab>
						<Tab value={batchType.scheduleCourse}>04. Schedule</Tab>
						<Tab value={batchType.batchEvents}>05. Events</Tab>
					</TabList>
				</Tabs>

				<Box
					sx={{
						p: 2,
						border: "1px solid",
						borderColor: "divider",
						borderRadius: "sm",
						background: "white",
					}}
				>
					{renderTabsInfo(activeTab)}
				</Box>
			</Box>
		</Box>
	);
};

export default WithAuth(BatchInfo);
