import { Box, Typography } from "@mui/joy";
import React from "react";
import CodeHighlighter from "../../../utils/code-highlighter";

const CodeTask = ({ task }) => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			{task?.task_progress?.value && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontSize: "14px", fontWeight: "700" }}
					>
						Code Submitted
					</Typography>
					<CodeHighlighter
						language={task?.task_progress?.value?.task_answer?.language}
						code={task?.task_progress?.value?.task_answer?.code}
						height={"200px"}
					/>
				</Box>
			)}
		</Box>
	);
};

export default CodeTask;
