import { createSlice, current } from "@reduxjs/toolkit";

import { addBatchThunk, fetchBatchByIdThunk, fetchBatchesThunk, fetchStudentsByBatchIdThunk } from "../actions/batchAction";
import { batchType } from "../../Constants/batch-tabs";
import { fetchAllTenantsThunk, getCoursesByTenantIdThunk } from "../actions/tenantAction";

const initialState = {
	batches: [],
	tenants: [],
	courses: [],
	teacherInfo: null,
	students: [],
	batchDetails: null,
	selectedTenant: null,
	selectedCourse: null,
	activeTab: batchType.batchDetails,
	isFormFilled: {
		batchDetails: false,
		verifyStudents: false,
		scheduleCourse: false,
		teacherDetails: false,
	},
	updatedBatchInfo: null,
	loading: false,
	batchLoading: false,
	error: null,
	unsavedChanges: null,
};

const batchSlice = createSlice({
	name: "batch",
	initialState,
	reducers: {
		clearAll: (state) => {
			state.batches = [];
			state.tenants = [];
			state.courses = [];
			state.students = [];
			state.batchDetails = null;
			state.selectedTenant = null;
			state.selectedCourse = null;
			state.activeTab = batchType.batchDetails;
			state.isFormFilled = {
				batchDetails: false,
				verifyStudents: false,
				scheduleCourse: false,
				teacherDetails: false,
			};
			state.loading = false;
			state.error = null;
		},
		deleteBatchFromBatches: (state, action) => {
			let currentBatches = current(state).batches;
			state.batches = {
				...state?.batches,
				data: { data: state.batches?.data?.data?.filter((batch) => batch?.batch_id !== action.payload) },
			};
		},
		selectTenant(state, action) {
			state.selectedTenant = action.payload;
			state.courses = [];
		},
		selectCourse(state, action) {
			state.selectedCourse = action.payload;
		},
		setFormFilled(state, action) {
			const { tab, filled } = action.payload;
			state.isFormFilled[tab] = filled;
		},
		setActiveTab: (state, action) => {
			state.activeTab = action.payload;
		},
		setEmptyFormFilled: (state) => {
			state.isFormFilled = {
				batchDetails: false,
				verifyStudents: false,
				scheduleCourse: false,
				teacherDetails: false,
			};
		},
		addTeacherInfo: (state, action) => {
			state.teacherInfo = action.payload || null;
		},
		setUnsavedChanges: (state, action) => {
			state.unsavedChanges = action.payload;
		},
		setUpdatedBatchInfo: (state, action) => {
			state.updatedBatchInfo = action.payload;
		},
		updateBatch: (state, action) => {
			let currentBatch = current(state).batchDetails;
			state.batchDetails = {
				...currentBatch,
				data: {
					...currentBatch.data,
					end_date: action.payload.end_date,
					start_date: action.payload.start_date,
					name: action.payload.name,
					external_batch_id: action.payload.external_batch_id,
				},
			};
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(fetchBatchesThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchBatchesThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.batches = action.payload;
				state.error = null;
			})
			.addCase(fetchBatchesThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(addBatchThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(addBatchThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.batchDetails = action.payload;
			})
			.addCase(addBatchThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchAllTenantsThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchAllTenantsThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.tenants = action.payload;
			})
			.addCase(fetchAllTenantsThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(getCoursesByTenantIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(getCoursesByTenantIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.courses = action.payload;
			})
			.addCase(getCoursesByTenantIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchStudentsByBatchIdThunk.pending, (state) => {
				state.loading = true;
				state.error = null;
			})
			.addCase(fetchStudentsByBatchIdThunk.fulfilled, (state, action) => {
				state.loading = false;
				state.students = action.payload;
			})
			.addCase(fetchStudentsByBatchIdThunk.rejected, (state, action) => {
				state.loading = false;
				state.error = action.payload;
			});

		builder
			.addCase(fetchBatchByIdThunk.pending, (state) => {
				state.batchLoading = true;
				state.error = null;
			})
			.addCase(fetchBatchByIdThunk.fulfilled, (state, action) => {
				state.batchLoading = false;
				state.batchDetails = action.payload;
				state.teacherInfo = action.payload?.data?.instructors;
			})
			.addCase(fetchBatchByIdThunk.rejected, (state, action) => {
				state.batchLoading = false;
				state.error = action.payload;
			});
	},
});

export const { setActiveTab, selectCourse, selectTenant, setFormFilled, clearAll, deleteBatchFromBatches, setEmptyFormFilled, addTeacherInfo, setUnsavedChanges, setUpdatedBatchInfo, updateBatch } =
	batchSlice.actions;
export default batchSlice.reducer;
