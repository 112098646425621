import { Box, Input, Typography } from "@mui/joy";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchStudentByIdThunk } from "../../../Store/actions/batchAction";
import Loader from "../../utils/Loader";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";

const StudentDetails = () => {
	const { studentInfo, studentLoading, studentError } = useSelector((state) => state.student);
	const dispatch = useDispatch();
	const location = useLocation();

	const student_id = location.pathname.split("/")[4];
	useEffect(() => {
		dispatch(fetchStudentByIdThunk(student_id));
	}, [student_id]);

	if (studentLoading) return <Loader />;

	if (studentError) return <Typography>Something went wrong</Typography>;

	const renderStudentDetails = () => {
		if (studentLoading) return <Loader />;
		else if (studentError) {
			return dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
		} else if (studentInfo?.data?.length > 0) {
			return (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<Box
						sx={{
							my: 2,
						}}
					>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								justifyContent: "space-between",
							}}
						>
							<Box
								sx={{
									width: "100%",
								}}
							>
								<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Name</Typography>
								<Input
									value={studentInfo?.data[0]?.name}
									placeholder='Enter Student Name'
									readOnly={true}
								/>
							</Box>
							{studentInfo?.data[0]?.college_details?.college_name && (
								<Box
									sx={{
										width: "100%",
									}}
								>
									<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>College Name</Typography>
									<Input
										value={studentInfo?.data[0]?.college_details?.college_name}
										placeholder='Enter College Name'
										readOnly={true}
									/>
								</Box>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Email</Typography>
						<Input
							value={studentInfo?.data[0]?.email}
							placeholder='Enter Student Email'
							readOnly={true}
						/>
					</Box>
					<Box
						sx={{
							my: 2,
						}}
					>
						<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Student Phone</Typography>
						<Input
							value={studentInfo?.data[0]?.mobile_number}
							placeholder='Enter Student Phone'
							readOnly={true}
						/>
					</Box>
				</Box>
			);
		}
	};

	return renderStudentDetails();
};

export default StudentDetails;
