import { Box, Typography } from "@mui/joy";
import React from "react";
import CodeHighlighter from "../../../utils/code-highlighter";
import { optionKey } from "../../../../Constants/option";
import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";

const McqTask = ({ task }) => {
	const chosenOption = task?.task_progress?.value?.task_answer?.option;
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
			}}
		>
			{task?.actual_task?.metadata?.image_url && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
					<img
						src={task?.actual_task?.metadata?.image_urll}
						alt='Preview'
						style={{ width: "100%", borderRadius: "10px", height: "100%", zIndex: 1000, objectFit: "contain" }}
					/>
				</Box>
			)}
			{task?.actual_task?.metadata?.code_snippet && (
				<Box sx={{ display: "flex", flexDirection: "column", gap: 1, height: "200px", width: "100%", my: 1 }}>
					<CodeHighlighter
						code={task?.actual_task?.metadata?.code_snippet}
						height={"200px"}
					/>
				</Box>
			)}
			<Box>
				<Typography
					level='h6'
					sx={{ color: "#000", fontSize: "14px", fontWeight: "700" }}
				>
					Options
				</Typography>
				{task?.actual_task?.metadata?.mcq_options.map((option, index) => {
					const isChosen = option.text === chosenOption?.text;
					const isCorrect = option.correct;
					const isWrongChosen = isChosen && !isCorrect;
					return (
						<Box
							key={index}
							sx={{ mb: 0.5, display: "flex", alignItems: "center" }}
						>
							<Typography
								sx={{
									fontWeight: 700,
									color: isCorrect
										? "#42B25B" // Always green for the correct option
										: isChosen
										? "#D55442" // Red for incorrectly chosen option
										: "#050519", // Default color for non-chosen options
									px: 2,
									fontSize: "1rem",
								}}
							>
								{optionKey[index + 1]}. &nbsp; {option.text}
							</Typography>

							{/* Show "Chosen option" only when the chosen option is incorrect */}
							{isWrongChosen && (
								<Typography
									sx={{
										color: "#6e6e6f", // Red for wrong choice message
										fontSize: "0.875rem",
										fontWeight: 600,
										mb: -0.2, // Add some margin to align it properly
									}}
								>
									Chosen Option
								</Typography>
							)}
						</Box>
					);
				})}
			</Box>
		</Box>
	);
};

export default McqTask;
