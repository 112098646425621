import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchStudentSubmissionThunk } from "../../../Store/actions/batchAction";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/joy";
import Loader from "../../utils/Loader";
import { showToast } from "../../../Store/slices/commonSlice";
import { ToastType } from "../../../Constants/toast-type";
import SubmissionDashboard from "./submission-dashboard";
import SubmissionReport from "./student-submission-report";

const StudentSubmission = () => {
	const { submissionInfo, submissionLoading, submissionError } = useSelector((state) => state.student);

	const location = useLocation();

	const studentId = location.pathname.split("/")[4];
	const batchId = location.pathname.split("/")[2];

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(fetchStudentSubmissionThunk({ studentId, batchId }));
	}, []);

	const renderSubmissionInfo = () => {
		if (submissionLoading)
			return (
				<Box>
					<Loader />
				</Box>
			);
		else if (submissionError) {
			return dispatch(showToast({ toastMessage: "Something went wrong", toastType: ToastType.ERROR }));
		} else if (submissionInfo?.data) {
			return (
				<Box>
					<SubmissionDashboard submissionInfo={submissionInfo} />
					<SubmissionReport submissionInfo={submissionInfo} />
				</Box>
			);
		} else {
			return (
				<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "30vh", borderRadius: "8px", bgcolor: "#f4f4f4", p: 2, mt: 2 }}>
					<Typography
						level='h6'
						sx={{ color: "#000", fontWeight: 500 }}
					>
						Add Tasks to the Topic for the students to do.
					</Typography>
				</Box>
			);
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
			}}
		>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					padding: "0 1rem",
				}}
			>
				<Typography
					level='h5'
					sx={{ color: "#000", fontWeight: 500 }}
				>
					Submissions {submissionInfo?.data?.StudentSubmissions?.TasksCount?.completedTasks}
				</Typography>
			</Box>
			<Box sx={{ borderBottom: "1px solid rgba(0, 0, 0, 0.30)", my: 1 }}></Box>
			<Box
				sx={{
					borderRadius: "sm",
				}}
			>
				{renderSubmissionInfo()}
			</Box>
		</Box>
	);
};

export default StudentSubmission;
