import React, { useEffect, useState } from "react";
import { fetchCourseDetailsThunk } from "../../../../Store/actions/courseAction";
import { useDispatch, useSelector } from "react-redux";
import { Box, IconButton, Typography } from "@mui/joy";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MenuIcon from "@mui/icons-material/Menu";
import { Droppable, DragDropContext, Draggable } from "react-beautiful-dnd";
import { checkAllNodesInTopicSessions, getAllTopics, getTopicInfoFromNode } from "../../../../helpers/node";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { Popper } from "@mui/material";

import { addTopicSessionThunk, fetchTopicSessionByBatchIdThunk, updateTopicSessionThunk } from "../../../../Store/actions/topicSessionAction";

import { DateTime } from "luxon";
import { setTopicSessions, updateTopicSession } from "../../../../Store/slices/topicSessionSlice";
import { showToast } from "../../../../Store/slices/commonSlice";
import { ToastType } from "../../../../Constants/toast-type";
import { useLocation, useNavigate } from "react-router-dom";
import { setFormFilled, setUnsavedChanges } from "../../../../Store/slices/batchSlice";
import { batchType } from "../../../../Constants/batch-tabs";
import Loader from "../../../utils/Loader";

const ScheduleCourse = () => {
	const [date, setDate] = useState(null);
	const [openNodeId, setOpenNodeId] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const { batchDetails } = useSelector((state) => state.batch);
	const { courseData, loading: courseDataLoader } = useSelector((state) => state.course);
	const { topicSessions, loading, error } = useSelector((state) => state.topic);
	const dispatch = useDispatch();

	const location = useLocation();
	const navigate = useNavigate();

	const pathname = location.pathname;

	useEffect(() => {
		if (batchDetails?.data?.courses.length > 0) {
			dispatch(fetchCourseDetailsThunk({ courseId: batchDetails?.data?.courses[0], batchId: batchDetails?.data?.batch_id }));
			dispatch(fetchTopicSessionByBatchIdThunk(batchDetails?.data?.batch_id));
		}
	}, [dispatch]);

	const getTheTopicSessionNode = (nodeId, topicSessions) => {
		const topicSessionNode = topicSessions?.find((session) => session.node_id === nodeId);
		return topicSessionNode;
	};

	//if the topic session is already present or if the topic session is completely added then i need to activate a button
	// if it is not then we should not allow the button to be clicked

	const onDragEnd = async (result) => {
		if (!result.destination) return;
		// if (batchDetails?.data?.start_date < DateTime.now().toISO()) {
		// 	dispatch(showToast({ toastMessage: "Cannot update the schedule of the already started batch", toastType: ToastType.ERROR }));
		// 	return;
		// }
		const { source, destination } = result;
		if (source?.index === destination?.index) return;

		const sourceStartDate = getTheTopicSessionNode(source?.index, topicSessions)?.start_date;
		const destinationStartDate = getTheTopicSessionNode(destination?.index, topicSessions)?.start_date;

		const sourceEndDate = getTheTopicSessionNode(source?.index, topicSessions)?.end_date;
		const destinationEndDate = getTheTopicSessionNode(destination?.index, topicSessions)?.end_date;

		if (sourceStartDate === destinationStartDate || !sourceStartDate || !destinationStartDate) return;

		const sourceNode = getTopicInfoFromNode(courseData.data, source?.index);
		const destinationNode = getTopicInfoFromNode(courseData.data, destination?.index);
		// console.log("src", sourceStartDate, sourceNode, "\n", "dest", destinationStartDate, destinationNode);

		if (sourceStartDate && destinationStartDate) {
			const response = await dispatch(
				updateTopicSessionThunk({
					topic_session_id: getTheTopicSessionNode(sourceNode.node_id, topicSessions)?.topic_session_id,
					start_date: destinationStartDate,
					end_date: destinationEndDate,
					batch_id: batchDetails?.data?.batch_id,
				})
			);

			const resp = await dispatch(
				updateTopicSessionThunk({
					topic_session_id: getTheTopicSessionNode(destinationNode.node_id, topicSessions)?.topic_session_id,
					start_date: sourceStartDate,
					end_date: sourceEndDate,
					batch_id: batchDetails?.data?.batch_id,
				})
			);

			dispatch(fetchTopicSessionByBatchIdThunk(batchDetails?.data?.batch_id));
			dispatch(fetchCourseDetailsThunk({ courseId: batchDetails?.data?.courses[0], batchId: batchDetails?.data?.batch_id }));
			//update the ui
			//sort the nodes based on the start date
			//render the nodes
		}
	};

	const getItemStyle = (isDragging, draggableStyle) => ({
		userSelect: "none",
		boxShadow: isDragging ? "0 4px 12px rgba(0, 0, 0, 0.3)" : "none",
		border: isDragging ? "1px solid rgba(0, 0, 0, 0.2)" : "1px solid transparent",
		borderRadius: "4px",
		transition: "box-shadow 0.2s ease, border 0.2s ease",
		...draggableStyle,
	});
	const getListStyle = (isDraggingOver) => ({
		background: isDraggingOver ? "#e0f7fa" : "",
		borderRadius: "8px",
		border: isDraggingOver ? "2px dashed #00796b" : "none",
		// height: isDraggingOver ? "auto" : "100%",
		// padding: isDraggingOver ? 0 : 1,
		overflowX: "hidden",
		// boxShadow: isDraggingOver ? "0 4px 8px rgba(0, 0, 0, 0.2)" : "none",
		transition: "background 0.3s ease, border 0.3s ease",
	});

	const handleClick = (event, nodeId) => {
		setOpenNodeId((prev) => (prev === nodeId ? null : nodeId));
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleDateChange = (date) => {
		const formattedDate = `${date?.c?.day}/${date?.c?.month}/${date?.c?.year}`;
		setDate(formattedDate);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpenNodeId(null);
	};

	const handleAccept = async (node) => {
		// if (batchDetails?.data?.start_date < DateTime.now().toISO()) {
		// 	dispatch(showToast({ toastMessage: "Cannot update the schedule of the already started batch", toastType: ToastType.ERROR }));
		// 	return;
		// }
		const start_date = date;
		const end_date = date;

		const format = "d/M/yyyy";
		const utcStartDate = DateTime.fromFormat(date, format).toUTC().toISO();
		const utcEndDate = DateTime.fromFormat(start_date, format).plus({ days: 1 }).minus({ milliseconds: 1 }).toUTC().toISO();

		const batch_id = batchDetails?.data?.batch_id;

		const topicSessionNode = topicSessions?.find((session) => session.node_id === node?.node_id);
		const dateValue = topicSessionNode ? DateTime.fromISO(topicSessionNode?.start_date) : null;
		const isValidDate = dateValue?.isValid;

		if (isValidDate) {
			const response = await dispatch(updateTopicSessionThunk({ start_date: utcStartDate, end_date: utcEndDate, topic_session_id: topicSessionNode?.topic_session_id, batch_id }));

			if (response?.payload?.data) {
				dispatch(updateTopicSession(response?.payload?.data));
				setAnchorEl(null);
				setOpenNodeId(null);
			}
			return;
		}

		const response = await dispatch(addTopicSessionThunk({ start_date: utcStartDate, end_date: utcEndDate, batch_id, node_id: node?.node_id }));
		if (response?.payload?.data) {
			dispatch(setUnsavedChanges(batch_id));
			dispatch(setTopicSessions(response?.payload?.data));

			if (pathname?.split("/")[2] === "addNewBatch") {
				dispatch(setFormFilled({ tab: batchType.scheduleCourse, filled: true }));
			}
			setAnchorEl(null);
			setOpenNodeId(null);
		}
	};

	const renderDateTimePicker = (childNode) => {
		if (loading)
			return (
				<Box>
					<Loader />
				</Box>
			);
		else if (error === "You are not authorized to access this batch.") {
			dispatch(showToast({ toastMessage: error, toastType: ToastType.ERROR }));
			setTimeout(() => {
				navigate("/batches");
			}, 3000);
		} else {
			const nodeDate = topicSessions?.find((session) => session.node_id === childNode?.node_id);
			const dateValue = nodeDate ? DateTime.fromISO(nodeDate?.start_date) : null;
			const isValidDate = dateValue?.isValid;

			return (
				<LocalizationProvider dateAdapter={AdapterLuxon}>
					<Box sx={{ width: "10%", mr: 2 }}>
						<IconButton
							component='label'
							variant='contained'
							color='primary'
							aria-describedby={childNode?.node_id}
							sx={{
								borderRadius: "md",
							}}
							onClick={(event) => handleClick(event, childNode?.node_id)}
						>
							<Typography
								sx={{
									borderBottom: !isValidDate ? "2px solid black" : "none",
									width: "100%",
									height: "100%",
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
								}}
							>
								{isValidDate ? dateValue.toFormat("dd MMM") : ""}
							</Typography>
						</IconButton>

						<Popper
							id={childNode?.node_id}
							open={openNodeId === childNode?.node_id}
							anchorEl={anchorEl}
							placement='bottom'
							sx={{ zIndex: 1000, border: "1px solid black" }}
						>
							<StaticDatePicker
								onChange={handleDateChange}
								onAccept={() => handleAccept(childNode)}
								onClose={handleClose}
								slots={{
									toolbar: null,
								}}
								slotProps={{
									actionBar: {
										sx: {
											marginTop: -6, // Remove margin
											padding: "8px", // Adjust padding if necessary
											justifyContent: "flex-end", // Align buttons to the end
											"& button": {
												margin: "0 4px", // Set a small margin between buttons
											},
										},
									},
								}}
								value={isValidDate ? dateValue : null}
								minDate={DateTime.fromISO(batchDetails?.data?.start_date)}
								maxDate={DateTime.fromISO(batchDetails?.data?.end_date)}
								sx={{ maxHeight: "22rem", overflowY: "auto", backgroundColor: "white" }}
							/>
						</Popper>
					</Box>
				</LocalizationProvider>
			);
		}
	};

	const renderNode = (childNode, parentNode) => {
		if (childNode?.node_id) {
			return (
				<>
					<Draggable
						draggableId={`${childNode.node_id}`}
						index={childNode.node_id}
					>
						{(provided, snapshot) => (
							<Box
								ref={provided.innerRef}
								{...provided.draggableProps}
								{...provided.dragHandleProps}
								style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
								sx={{ display: "flex", alignItems: "center", width: "100%", overflowX: "hidden", my: 2 }}
								key={childNode?.node_id}
							>
								{renderDateTimePicker(childNode)}
								<Box sx={{ backgroundColor: "#f4f4f4", width: "95%", display: "flex", alignItems: "center", p: 1, borderRadius: "10px" }}>
									<MenuIcon />
									<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", px: 3 }}>
										<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
											<Box sx={{ display: "flex", alignItems: "center" }}>
												<Typography
													noWrap
													sx={{
														width: "200px",
														textAlign: "left",
													}}
												>
													{parentNode?.node_name}
												</Typography>
												<ChevronRightIcon
													sx={{
														color: "gray",
														mx: 1,
													}}
													fontSize='10px'
												/>
											</Box>
											<Typography
												noWrap
												sx={{
													width: "400px",
													textAlign: "left",
												}}
											>
												{childNode.node_name}
											</Typography>
										</Box>
										<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
											<Typography
												sx={{ color: "GrayText" }}
												level='title-sm'
											>
												{childNode?.tasks?.length || 0} Task
											</Typography>
											<Typography
												sx={{ color: "GrayText" }}
												level='title-sm'
											>
												{childNode?.videos?.length || 0} Videos
											</Typography>
										</Box>
									</Box>
								</Box>
							</Box>
						)}
					</Draggable>
				</>
			);
		} else {
			return null;
		}
	};

	const renderAccordian = (node) => {
		if (node?.children && node?.children?.length > 0) {
			const sortedNodesByStartDate = [...node?.children]?.sort((a, b) => {
				const aNode = getTheTopicSessionNode(a.node_id, topicSessions);
				const bNode = getTheTopicSessionNode(b.node_id, topicSessions);
				return DateTime.fromISO(aNode?.start_date) - DateTime.fromISO(bNode?.start_date);
			});

			return (
				<DragDropContext onDragEnd={onDragEnd}>
					<Droppable droppableId={`${node.node_id}`}>
						{(provided, snapshot) => (
							<>
								<Box
									key={node.node_id}
									{...provided.droppableProps}
									ref={provided.innerRef}
									style={getListStyle(snapshot.isDraggingOver)}
								>
									<Box sx={{ my: 1, overflowY: "auto", overflowX: "hidden" }}>{sortedNodesByStartDate?.map((childNode) => renderNode(childNode, node))}</Box>
									{provided.placeholder}
								</Box>
							</>
						)}
					</Droppable>
				</DragDropContext>
			);
		} else {
			return renderNode(null, node);
		}
	};

	const renderCourseData = () => {
		if (courseDataLoader)
			return (
				<Box>
					<Loader />
				</Box>
			);
		else if (courseData?.data?.children && courseData?.data?.children?.length > 0) {
			return <Box sx={{ maxHeight: "60vh", overflowY: "auto" }}>{courseData?.data?.children?.map((node) => renderAccordian(node))}</Box>;
		} else {
			return <Box>No topics available</Box>;
		}
	};

	return (
		<Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", borderBottom: "1px solid #888", px: 1 }}>
				<Typography sx={{ textAlign: "start", mb: 1, fontWeight: "600" }}>Schedule Course</Typography>
			</Box>
			{renderCourseData()}
		</Box>
	);
};

export default ScheduleCourse;
