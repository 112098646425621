import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Box, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy";
import React, { useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ReactComponent as CodeIcon } from "../../../Assets/code.svg";
import { ReactComponent as DocIcon } from "../../../Assets/doc.svg";
import { ReactComponent as MCQIcon } from "../../../Assets/mcq.svg";
import { DateTime } from "luxon";
import StudentTaskDetails from "./student-task-details";

const SubmissionReport = ({ submissionInfo }) => {
	const [taskModal, setTaskModal] = useState(null);
	const getImageComponent = (type) => {
		switch (type) {
			case "CODE":
				return <CodeIcon />;
			case "MCQ":
				return <MCQIcon />;
			case "DOC":
				return <DocIcon />;
			default:
				return <DocIcon />;
		}
	};

	const getTaskStatus = (task_status) => {
		if (task_status === "PENDING") {
			return "Not Started";
		} else if (task_status === "COMPLETED") {
			return "Submitted";
		} else if (task_status === "IN_PROGRESS") {
			return "In Progress";
		} else {
			return "Not Started";
		}
	};

	const getTopicStatus = (task_status) => {
		if (task_status === "PENDING") {
			return "Not Started";
		} else if (task_status === "COMPLETED") {
			return "Completed";
		} else if (task_status === "IN_PROGRESS") {
			return "In Progress";
		} else {
			return "Not Started";
		}
	};

	const getColor = (task) => {
		if (task === "Not Started") {
			return "#6e6e6f";
		} else if (task === "Completed") {
			return "#42B25B";
		} else if (task === "In Progress") {
			return "#D48134";
		}
	};

	const calculateTotalPoints = (tasks) => {
		return tasks.reduce((acc, task) => acc + task.actual_task.points, 0);
	};

	const calculateTotalTaskCompleted = (tasks) => {
		return tasks.filter((task) => task.task_status === "COMPLETED").length;
	};

	const handleClose = () => {
		setTaskModal(null);
	};

	const renderTaskModal = (task, index, topic) => {
		return (
			<Modal
				open={taskModal === task.task_id}
				onClose={handleClose}
			>
				<ModalDialog sx={{ width: 800 }}>
					<ModalClose />
					<Typography level='h4'>{task?.actual_task?.task_title || `Task ${index + 1}`}</Typography>
					<StudentTaskDetails
						task={task}
						topic={topic}
					/>
				</ModalDialog>
			</Modal>
		);
	};

	const renderTasks = (tasks, topic) => {
		if (tasks.length === 0) {
			return (
				<Box
					sx={{
						bgcolor: "#fff",
						padding: "1rem .7rem",
						borderRadius: "8px",
					}}
				>
					<Typography>No tasks found</Typography>
				</Box>
			);
		}
		return tasks.map((task, index) => (
			<>
				{renderTaskCard(task, index)}
				{taskModal === task?.task_id && renderTaskModal(task, index, topic)}
			</>
		));
	};

	const renderTaskCard = (task, index) => {
		return (
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					bgcolor: "#fff",
					borderRadius: "8px",
					cursor: "pointer",
					padding: "1rem .7rem",
				}}
				onClick={() => setTaskModal(task?.task_id)}
			>
				<Box
					sx={{
						display: "flex",
						gap: 1,
						alignItems: "center",
					}}
				>
					{getImageComponent(task?.actual_task?.task_type)}
					<Typography
						sx={{
							fontSize: "1rem",
							fontWeight: 600,
							color: "#3838f1",
						}}
					>
						{task?.actual_task?.task_title || `Task ${index + 1}`}
					</Typography>
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: 1,
					}}
				>
					<Typography
						sx={{
							color: "6e6e6f",
							fontSize: "14px",
						}}
					>
						{getTaskStatus(task?.task_status)}
					</Typography>
					<Typography
						sx={{
							color: "6e6e6f",
							fontSize: "14px",
						}}
					>
						{task?.actual_task?.task_type}
					</Typography>
					<Typography
						sx={{
							color: "6e6e6f",
							fontSize: "14px",
						}}
					>
						{task?.actual_task?.points} pts
					</Typography>
				</Box>
			</Box>
		);
	};

	const renderTopics = (topic) => {
		return (
			<Accordion sx={{}}>
				<AccordionSummary
					indicator={<ExpandMoreIcon />}
					sx={{
						borderRadius: "8px",
						":hover": {
							bgcolor: "#f4f4f4",
						},
						padding: "1rem .7rem",
						bgcolor: "#f4f4f4",
					}}
				>
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								gap: 2,
							}}
						>
							<Typography
								sx={{
									fontSize: "14px",
									color: "#6e6e6f",
								}}
							>
								{DateTime.fromISO(topic?.startDate).toFormat("dd LLL yy")}
							</Typography>
							<Typography
								sx={{
									fontSize: "1rem",
									fontWeight: 600,
									color: "#050519",
								}}
							>
								{topic?.topic_name}
							</Typography>
						</Box>
						<Box
							sx={{
								display: "flex",
								gap: 2,
								alignItems: "center",
							}}
						>
							<Typography
								sx={{
									fontSize: "14px",
									fontWeight: 600,
									color: getColor(getTopicStatus(topic?.status)),
								}}
							>
								{topic?.tasks?.length > 0 ? (
									getTopicStatus(topic?.status)
								) : (
									<Typography
										sx={{
											fontSize: "14px",
											color: "#FC5A5A",
										}}
									>
										No tasks Created
									</Typography>
								)}
							</Typography>
							{topic?.tasks?.length > 0 && (
								<Box
									sx={{
										display: "flex",
										gap: 2,
										alignItems: "center",
									}}
								>
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: 600,
											color: "#6e6e6f",
										}}
									>
										{calculateTotalTaskCompleted(topic?.tasks)} / {topic?.tasks?.length} Submissions
									</Typography>
									<Typography
										sx={{
											fontSize: "14px",
											fontWeight: 600,
											color: "#6e6e6f",
										}}
									>
										{topic?.points} / {calculateTotalPoints(topic?.tasks)} pts
									</Typography>
								</Box>
							)}
						</Box>
					</Box>
				</AccordionSummary>

				<AccordionDetails
					sx={{
						bgcolor: "#f4f4f4",
						padding: "0 .7rem",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 2,
							maxHeight: "25vh",
							overflowY: "auto",
							padding: "0 .2rem",
							mb: 2,
						}}
					>
						{renderTasks(topic?.tasks, topic)}
					</Box>
				</AccordionDetails>
			</Accordion>
		);
	};

	const values = submissionInfo?.data?.StudentSubmissions?.Topics?.slice().sort((a, b) => {
		const dateA = DateTime.fromISO(a.startDate).toMillis();
		const dateB = DateTime.fromISO(b.startDate).toMillis();
		return dateA - dateB;
	});

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 2,
				px: 2,
				mt: 3,
				maxHeight: "36vh",
				overflowY: "auto",
			}}
		>
			{values.map((topic) => renderTopics(topic))}
		</Box>
	);
};

export default SubmissionReport;
